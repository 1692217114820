<template>
  <div class="home">
    <img class="banner" src="@/assets/xdh_banner.png" alt="" />
    <div class="body">
      <div class="aside">
        <img src="@/assets/xdh_left_img.png" alt="" />
      </div>

      <div class="main">
        <div class="title">摘星辰足球系统平台</div>
        <el-divider></el-divider>
        <div class="qrcode-wrap">
          <div class="qrcode-item">
            <img src="@/assets/qrcode.png" alt="" />
            <div class="text">安卓APP</div>
          </div>

          <div class="qrcode-item">
            <img src="@/assets/qrcode.png" alt="" />
            <div class="text">微信小程序</div>
          </div>

          <div class="qrcode-item">
            <img src="@/assets/qrcode.png" alt="" />
            <div class="text">移动网址</div>
          </div>
        </div>

        <div class="title">摘星辰足球传媒平台</div>
        <el-divider></el-divider>
        <div class="media-wrap">
          <div class="media-item">
            <img src="@/assets/toutiao.png" alt="" />
            <div class="content">
              <div class="media-title">今日头条</div>
              <div class="media-sub-title">摘星辰足球</div>
            </div>
          </div>

          <div class="media-item">
            <img src="@/assets/douyu.png" alt="" />
            <div class="content">
              <div class="media-title">斗鱼直播</div>
              <div class="media-sub-title">摘星辰足球</div>
            </div>
          </div>

          <div class="media-item">
            <img src="@/assets/douyin.png" alt="" />
            <div class="content">
              <div class="media-title">抖音</div>
              <div class="media-sub-title">摘星辰足球</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {},
  computed: {
    activeMenu() {
      const route = this.$route;
      const { path } = route;
      return path;
    }
  },
  methods: {
    handleClick() {}
  }
};
</script>
<style lang="scss" scoped>
.body {
  display: flex;
  padding: 15px;
  background: #fff;
  .aside {
    margin-right: 20px;

    .weixin {
      height: 78px;
      background: #f6f7f8;
      display: flex;
      align-items: center;
      .icon-weixin {
        font-size: 32px;
        color: #c8c8c8;
        margin-left: 15px;
        margin-right: 15px;
        .content {
          font-size: 16px;
          line-height: 22px;
          color: #584f4f;
        }
      }
    }
  }

  .main {
    flex: 1;
  }
}
.title {
  height: 28px;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #16a085;
  &::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 15px;
    background: #16a085;
    margin-right: 5px;
  }
}
.el-divider {
  margin-top: 5px;
  margin-bottom: 15px;
}
.banner {
  display: block;
}

.qrcode-wrap {
  display: flex;
  justify-content: space-around;
  margin: 45px 0;
  .qrcode-item {
    text-align: center;
    .text {
      font-size: 14px;
    }
  }
}

.media-wrap {
  display: flex;
  justify-content: space-around;
  margin: 45px 0;

  .media-item {
    width: 206px;
    height: 86px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    img {
      margin-left: 15px;
    }
    .content {
      flex: 1;
      text-align: center;
      .media-title {
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        margin-bottom: 8px;
      }

      .media-sub-title {
        font-size: 14px;
        line-height: 20px;
        color: #666666;
      }
    }
  }
}
</style>
